import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <h1>Soon you will fin a lot of cool guitar packs here to sell!</h1>
      <h5>Meanwhile you can follow me at <a href="https://www.instagram.com/guitarwag/">https://www.instagram.com/guitarwag/</a></h5>
    </main>
  )
}

export default IndexPage
